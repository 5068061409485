<template>
  <div>
    <component v-bind:is="componentName" v-if="isLogged === false" @click.native="ui.dialogSignUp=true"></component>
    <v-dialog v-model="ui.dialogSignUp" width="560">
      <v-card class="rounded-lg py-2">
        <validation-observer ref="observer"
                             v-slot="{invalid}">
          <v-form @submit.prevent="submitRegister">
            <v-toolbar dense flat>
              <v-btn v-show="componentName === 'RegisterSignIn'" icon @click="ui.dialogSignUp = false">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <h2>Regístrate Ahora</h2>
              <v-spacer></v-spacer>
              <v-btn icon @click="ui.dialogSignUp = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <p class="mb-3">Escribe tus datos</p>
            <v-card-text class="pb-0">
              <validation-provider v-slot="{errors}"
                                   rules="required|min:6">
                <v-text-field v-model="registerForm.fullName"
                              :error-messages="errors"
                              clearable
                              color="#00999a"
                              label="Nombre y Apellidos"
                              outlined>
                </v-text-field>
              </validation-provider>

              <validation-provider v-slot="{errors}" rules="isValidPhoneNumber">
                <vue-tel-input-vuetify v-model="registerForm.phone"
                                       :error-messages="errors"
                                       :onlyCountries="['cu']"
                                       :valid-characters-only="true"
                                       class="myPhone"
                                       clearable
                                       color="#00999a"
                                       defaultCountry="cu"
                                       label="Número de Teléfono"
                                       mode="international"
                                       outlined
                                       placeholder=""
                                       required>
                </vue-tel-input-vuetify>
              </validation-provider>

              <ValidationObserver>
                <v-row>
                  <v-col cols="12" sm="6" class="pb-sm-3 pb-0">
                    <validation-provider v-slot="{errors}"
                                         name="password"
                                         rules="required|min:8"
                                         vid="password">
                      <v-text-field v-model="registerForm.password"
                                    :append-icon="!showEye ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                                    :error-messages="errors"
                                    :type="!showEye ? 'password' : 'text'"
                                    color="primary"
                                    label="Contraseña"
                                    outlined
                                    @click:append="showEye = !showEye">
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-sm-3 pt-0">
                    <validation-provider v-slot="{errors}"
                                         rules="required|min:8|confirm:@password">
                      <v-text-field v-model="registerForm.repeatPassword"
                                    :append-icon="!showEye ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                                    :error-messages="errors"
                                    :type="!showEye ? 'password' : 'text'"
                                    color="#00999a"
                                    label="Repetir Contraseña"
                                    outlined
                                    @click:append="showEye = !showEye">
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </ValidationObserver>

              <v-checkbox class="mt-0 pt-0" v-model="registerForm.termsAccepted">
                <template v-slot:label>
                  <div>
                    Acepto los
                    <a href="/terms"
                       target="_blank"
                       @click.stop>
                      Términos de servicio
                    </a>
                    y
                    <a href="/policy"
                       target="_blank"
                       @click.stop>
                      Política de privacidad
                    </a>
                    del sitio.
                  </div>
                </template>
              </v-checkbox>
            </v-card-text>

            <v-card-actions>
              <v-btn :disabled="!registerForm.termsAccepted"
                     :loading="registerForm.loading"
                     block
                     class="text-none font-weight-medium "
                     color="primary"
                     large
                     rounded
                     type="submit">
                Crear una cuenta
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import services from "@/services"
import {min, required} from 'vee-validate/dist/rules'
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate'
import {isValidPhoneNumber} from 'libphonenumber-js'

const RegisterHome = () => import(/* webpackPrefetch: true */ "../components/RegisterHome.vue");
const RegisterHome2 = () => import(/* webpackPrefetch: true */ "../components/RegisterHome2.vue");
const RegisterSignIn = () => import(/* webpackPrefetch: true */ "../components/RegisterSignIn.vue");

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'El campo no puede estar vacio.',
})
extend('min', {
  ...min,
  message: 'El campo debe contener más de {length} caracteres.',
})
extend('confirm', {
  params: ['target'],
  validate(value, {target}) {
    return value === target;
  },
  message: 'Las contraseñas no coinciden.'
});
extend('isValidPhoneNumber', {
  ...required,
  message: 'El número de teléfono no es válido.',
  validate: function (val) {
    return isValidPhoneNumber(val);
  }
})

export default {
  name: "SignUp",
  components: {
    ValidationProvider,
    ValidationObserver,
    RegisterHome,
    RegisterHome2,
    RegisterSignIn
  },
  data: () => ({
    ui: {
      dialogSignUp: false
    },
    showEye: false,
    dialog1: false,
    registerForm: {
      fullName: '',
      phone: '',
      password: '',
      repeatPassword: '',
      loading: false,
      termsAccepted: false
    },
  }),
  methods: {

    /**
     * Register a new user.
     * @returns {Promise<void>}
     */
    submitRegister: async function () {
      const isValid = await this.$refs.observer.validate();
      if (!isValid)
        return;

      this.registerForm.loading = true;
      try {
        const result = await services.auth.register({
          fullName: this.registerForm.fullName,
          phone: this.cleanPhoneNumber(this.registerForm.phone),
          password: this.registerForm.password
        });
        this.$store.commit('auth/loginSuccess', {
          token: result.data.extra_data.token,
          user: result.data,
        });
        await this.$router.push({name: 'admin_welcome'});
      } catch (error) {
        console.log(error);
        this.$dialog.notify.error('El número de teléfono ya se encuentra registrado.', {
          position: 'bottom-right',
          timeout: 5000,
        })
      } finally {
        this.registerForm.loading = false;
      }
    },

    cleanPhoneNumber: function (phoneNumber) {
      phoneNumber = this.replaceAll(phoneNumber, ' ', '');
      phoneNumber = this.replaceAll(phoneNumber, '(', '');
      phoneNumber = this.replaceAll(phoneNumber, ')', '');
      phoneNumber = this.replaceAll(phoneNumber, '-', '');
      phoneNumber = phoneNumber.trim();
      return phoneNumber;
    },
    replaceAll: function (string, search, replace) {
      return string.split(search).join(replace)
    }
  },
  computed: {
    isLogged() {
      return this.$store.state.auth.loggedIn;
    },
  },
  props: ['componentName']
}
</script>

<style>
.v-card {
  text-align: center !important;
  padding: 0px 10px 30px 10px !important;
}

.myPhone > .country-code {
  margin-right: 10px !important;
}
</style>
