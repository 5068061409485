var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLogged === false)?_c(_vm.componentName,{tag:"component",nativeOn:{"click":function($event){_vm.ui.dialogSignUp=true}}}):_vm._e(),_c('v-dialog',{attrs:{"width":"560"},model:{value:(_vm.ui.dialogSignUp),callback:function ($$v) {_vm.$set(_vm.ui, "dialogSignUp", $$v)},expression:"ui.dialogSignUp"}},[_c('v-card',{staticClass:"rounded-lg py-2"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitRegister.apply(null, arguments)}}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.componentName === 'RegisterSignIn'),expression:"componentName === 'RegisterSignIn'"}],attrs:{"icon":""},on:{"click":function($event){_vm.ui.dialogSignUp = false}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('h2',[_vm._v("Regístrate Ahora")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.ui.dialogSignUp = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('p',{staticClass:"mb-3"},[_vm._v("Escribe tus datos")]),_c('v-card-text',{staticClass:"pb-0"},[_c('validation-provider',{attrs:{"rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"clearable":"","color":"#00999a","label":"Nombre y Apellidos","outlined":""},model:{value:(_vm.registerForm.fullName),callback:function ($$v) {_vm.$set(_vm.registerForm, "fullName", $$v)},expression:"registerForm.fullName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"isValidPhoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{staticClass:"myPhone",attrs:{"error-messages":errors,"onlyCountries":['cu'],"valid-characters-only":true,"clearable":"","color":"#00999a","defaultCountry":"cu","label":"Número de Teléfono","mode":"international","outlined":"","placeholder":"","required":""},model:{value:(_vm.registerForm.phone),callback:function ($$v) {_vm.$set(_vm.registerForm, "phone", $$v)},expression:"registerForm.phone"}})]}}],null,true)}),_c('ValidationObserver',[_c('v-row',[_c('v-col',{staticClass:"pb-sm-3 pb-0",attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":!_vm.showEye ? 'mdi-eye-off-outline' : 'mdi-eye-outline',"error-messages":errors,"type":!_vm.showEye ? 'password' : 'text',"color":"primary","label":"Contraseña","outlined":""},on:{"click:append":function($event){_vm.showEye = !_vm.showEye}},model:{value:(_vm.registerForm.password),callback:function ($$v) {_vm.$set(_vm.registerForm, "password", $$v)},expression:"registerForm.password"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-sm-3 pt-0",attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:8|confirm:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":!_vm.showEye ? 'mdi-eye-off-outline' : 'mdi-eye-outline',"error-messages":errors,"type":!_vm.showEye ? 'password' : 'text',"color":"#00999a","label":"Repetir Contraseña","outlined":""},on:{"click:append":function($event){_vm.showEye = !_vm.showEye}},model:{value:(_vm.registerForm.repeatPassword),callback:function ($$v) {_vm.$set(_vm.registerForm, "repeatPassword", $$v)},expression:"registerForm.repeatPassword"}})]}}],null,true)})],1)],1)],1),_c('v-checkbox',{staticClass:"mt-0 pt-0",scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Acepto los "),_c('a',{attrs:{"href":"/terms","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Términos de servicio ")]),_vm._v(" y "),_c('a',{attrs:{"href":"/policy","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Política de privacidad ")]),_vm._v(" del sitio. ")])]},proxy:true}],null,true),model:{value:(_vm.registerForm.termsAccepted),callback:function ($$v) {_vm.$set(_vm.registerForm, "termsAccepted", $$v)},expression:"registerForm.termsAccepted"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"text-none font-weight-medium ",attrs:{"disabled":!_vm.registerForm.termsAccepted,"loading":_vm.registerForm.loading,"block":"","color":"primary","large":"","rounded":"","type":"submit"}},[_vm._v(" Crear una cuenta ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }